// import App from './App.svelte';
import TodoMVC from './TodoMVC.svelte';

const app = new TodoMVC({
	target: document.querySelector('.todoapp')
	// props: {
	// 	name: 'world'
	// }
});

export default app;